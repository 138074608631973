var app = app || {};

var spBreak = 767;

app.init = function () {
  app.tabletViewport();
  app.accordion();
  app.initWow();
  app.scrollbar();
  app.form();
};

app.form = function () {
  /* form validations */
  if ($('form.js-validation').length) {
    function addError(myselfObj, message) {
      myselfObj.addClass('error');
      if (myselfObj.data('error-holder')) $(myselfObj.data('error-holder')).html(message).show();
      else myselfObj.prev('.text-error').html(message).show();

      $('.error-message').show();
    }

    function removeError(myselfObj) {
      myselfObj.removeClass('error');
      if (myselfObj.data('error-holder')) $(myselfObj.data('error-holder')).html('').hide();
      else myselfObj.prev('.text-error').html('').hide();

      $('.error-message').hide();
    }

    $('.js-validate').on('keyup blur change', function () {
      var targetObj = $(this);
      if (targetObj.hasClass('js-required') && targetObj.val() == '') {
        addError(targetObj, '必須項目を入力してください。');
      } else if (
        targetObj.hasClass('js-checkbox-required') &&
        $('[name="' + targetObj.attr('name') + '"]:checked').length == 0
      ) {
        addError(targetObj, '選択してください');
      } else if (
        targetObj.hasClass('js-email') &&
        targetObj
          .val()
          .match(
            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
          ) == null
      ) {
        addError(targetObj, 'メールアドレスの形式が正しくありません。');
      } else if (targetObj.hasClass('js-email-cf')) {
        var email = $('input[name="request_for_clinic_registration[email]"]').val();
        var confemail = targetObj.val();
        if (email != confemail) addError(targetObj, '確認用メールアドレスが一致しません');
        else removeError(targetObj);
      } else if (
        (targetObj.hasClass('js-zip1') && targetObj.val().match(/^[0-9][0-9][0-9]$/) == null) ||
        (targetObj.hasClass('js-zip2') && targetObj.val().match(/^[0-9][0-9][0-9][0-9]$/) == null)
      ) {
        addError(targetObj, '郵便番号を正しく入力してください。');
      } else if (
        (targetObj.hasClass('js-tel1') ||
          targetObj.hasClass('js-tel2') ||
          targetObj.hasClass('js-tel3')) &&
        targetObj.val().match(/^[0-9]{2,5}$/) == null
      ) {
        addError(targetObj, '電話番号を正しく入力してください。');
      } else if (targetObj.hasClass('js-kana') && targetObj.val().match(/^[ァ-ヶー　]+$/) == null) {
        addError(targetObj, 'カタカナで入力してください。');
      } else if (
        targetObj.hasClass('js-image') &&
        $(targetObj.data('image-required-flag') + ':checked').val() == 'true' &&
        targetObj.val() == ''
      ) {
        addError(targetObj, '写真を指定してください。');
      } else if (targetObj.hasClass('js-image-check')) {
        if (
          $('#js-image-area>img').length == 0 &&
          $("[name='" + targetObj.attr('name') + "']:checked").val() == 'true'
        ) {
          addError(targetObj, '写真を指定してください。');
        } else {
          removeError(targetObj);
        }
      } else {
        removeError(targetObj);
      }
    });
  }

  /* load image */
  if ($('.js-image-section').length) {
    var loadImage = function (input) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          $('.js-image img').attr('src', e.target.result);
          $('.js-image').show();
        };
        reader.readAsDataURL(input.files[0]);
      } else {
        $('.js-image img').attr('src', '');
        $('.js-image').hide();
      }
    };
    $('[name="request_for_clinic_registration[photo_up]"]').on('change', function () {
      if ($('[name="request_for_clinic_registration[photo_up]"]:checked').val() == 'true') {
        $('.js-image-section .file-upload').show();
      } else {
        $('.js-image-section .file-upload').hide();
      }
    });
    $('.js-btn-load').on('change', function () {
      loadImage(this);
      $(this).parent().addClass('is-current');
    });
  }

  /* text count */
  if ($('.js-text-count').length) {
    var target = $('.js-text-count');
    $(target.data('target')).on('keyup blur change', function () {
      var len = $(target.data('target')).val().length;
      target.html(len + '/300');
    });
  }

  /* check agreement */
  if ($('.js-check-agree').length) {
    if ($('input[id="request_for_clinic_registration_agreement"]').is(':checked')) {
      $('#btn-submit').attr('disabled', false);
    }
    $('input[id="request_for_clinic_registration_agreement"]').on('click', function () {
      if ($(this).is(':checked')) {
        $('#btn-submit').attr('disabled', false);
      } else {
        $('#btn-submit').attr('disabled', true);
      }
    });
  }
};

/* Mobile */
app.isMobile = function () {
  return window.matchMedia('(max-width: ' + spBreak + 'px)').matches;
};

/* Tabletviewport */
app.tabletViewport = function () {
  var viewport = document.getElementById('viewport');
  var viewports = {
    default: viewport.getAttribute('content'),
    tablet: 'width=1200, user-scalable=0',
    smartphone: 'width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=0',
  };
  var viewportSet = function () {
    if (screen.width >= spBreak + 1 && screen.width <= 1200) {
      viewport.setAttribute('content', viewports.tablet);
    } else {
      viewport.setAttribute('content', viewports.smartphone);
    }
  };
  viewportSet();
  window.onload = function () {
    viewportSet();
  };
  window.onresize = function () {
    viewportSet();
  };
};

/* Accordion */
app.accordion = function () {
  $('.js-btn-accordion').click(function () {
    $(this).stop().toggleClass('is-open');
    $(this).parent().find('.js-accordion').slideToggle();
    return false;
  });
};

/* Scrollbar */
app.scrollbar = function () {
  if ($('.js-scrollbar').length) {
    $('.js-scrollbar').mCustomScrollbar({
      theme: 'minimal-dark',
      scrollInertia: 10,
    });
  }
};

/* Animation */
app.initWow = function () {
  new WOW().init();
};

$(function () {
  app.init();
});
